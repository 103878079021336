<template>
  <section :class="{'relative': mobileFlights}">

      <div class="mobile__tabs" v-if="isMobile">
        <client-only>
          <Tabs :key="tabsKey" when-idle :tabs-info="tabsInfo">
            <v-container v-if="$store.state.device.isMobile">
              <div class="main-content">
                <div class="center-content">
                  <h1 class="slide-title" v-html="$texts.tagline">
                  </h1>
                  <slot/>
                </div>
              </div>
            </v-container>
            <Tab name="Attractions" prefix="attractions" :selected="activeTab === '#attractions'" v-if="initAttractions">
              <LazyHydrate when-idle on-interaction="click">
                <search :homePage="true"/>
              </LazyHydrate>
            </Tab>
            <Tab name="Hotels" prefix="hotels" :selected="activeTab === '#hotels'" v-if="initHotels">
              <LazyHydrate when-idle on-interaction="click">
                <SearchHotels/>
              </LazyHydrate>
            </Tab>
          </Tabs>
        </client-only>
      </div>
      <div v-else> </div>

    <!--TODO: uncomment to return flights filters -->
    <!--<FlightsSearchFilters v-show="mobileFlights"/>-->

      <top-section>
        <client-only>
          <Tabs :key="tabsKey" v-if="!isMobile" when-idle :tabs-info="tabsInfo">
            <Tab name="Attractions" prefix="attractions" :selected="activeTab === '#attractions'" v-if="initAttractions">
              <LazyHydrate when-idle on-interaction="click">
                <search :homePage="true"/>
              </LazyHydrate>
            </Tab>
            <Tab name="Hotels" prefix="hotels" :selected="activeTab === '#hotels'" v-if="initHotels">
              <LazyHydrate when-idle on-interaction="click">
                <SearchHotels/>
              </LazyHydrate>
            </Tab>
            <div>
              <Tab name="Events" prefix="events" :selected="activeTab === '#events'" v-if="initEvents">
                <LazyHydrate when-idle on-interaction="click">
                  <SearchEvents/>
                </LazyHydrate>
              </Tab>
              <Tab name="Flights" prefix="flights" :selected="activeTab === '#flights'" v-if="initFlights">
                <SearchFlights/>
              </Tab>
            </div>
          </Tabs>
        </client-only>
        <div v-if="isMobile"></div>
      </top-section>

    <LandingPageBottomRSG v-if="siteBrand === 'rsg'"/>
    <LandingPageBottomTRIPENING v-if="siteBrand === 'tripening'"/>
  </section>
</template>

<script>
import TopSection from '@/components/TopSection/TopSection'
import Search from '@/components/Search/Search'
import Tab from '@/components/Tab/Tab'
import Tabs from '@/components/Tabs/Tabs'
import SearchHotels from '@/components/SearchHotels/SearchHotels'
import SearchEvents from '@/components/SearchEvents/SearchEvents'
import SearchFlights from '@/components/Flights/SearchFlights/SearchFlights'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  data() {
    return {
      activeTab: '#hotels' ,
      tabsInfo: [{name: 'Hotels', isActive: true, prefix: 'hotels'}],
      tabsKey: 0
    }
  },
  computed: {
    siteBrand() {
      return this.$store.state.siteBrand
    },
    initAttractions() {
      return this.$config.initAttractions
    },
    initHotels() {
      return this.$config.initHotels
    },
    initEvents() {
      return this.$config.initEvents
    },
    initFlights() {
      return this.$config.initFlights
    },
    mobileFlights() {
      return (this.$store.state.device.isMobile) && this.initFlights && this.activeTab === '#flights'
    },
    isMobile() {
      return this.$store.state.device.isMobile
    }
  },
  components: {
    LazyHydrate,
    TopSection,
    Search,
    Tab,
    Tabs,
    LandingPageBottomRSG: () => import('@/components/LandingPage/rsg/LandingPageBottom'),
    LandingPageBottomTRIPENING: () => import('@/components/LandingPage/tripening/LandingPageBottom'),
    SearchHotels,
    SearchEvents,
    SearchFlights,
    FlightsSearchFilters: () => import('@/components/Flights/FlightsSearchFilters/FlightsSearchFilters')
  },
  async created() {
    this.updateTabs()
  },
  mounted() {
    if (!document.getElementsByClassName('tabs-wrapper')?.length) {
      this.updateTabs()
    }

    setTimeout(() => {
      if (this.$route.hash === '#mobileapps' && this.$refs.mobileapps?.$el) {
        window.scrollTo({
          top: this.$refs.mobileapps.$el.offsetTop,
          behavior: 'smooth'
        })
      }
    }, 1000)
  },
  methods: {
    updateTabs() {
      if (this.$route.hash === '#attractions' || this.$route.hash === '#hotels' || this.$route.hash === '#events' || this.$route.hash === '#flights') {
        this.activeTab = this.$route.hash
      }
      this.tabsInfo = []

      if (this.initHotels) {
        this.tabsInfo.push({name: 'Hotels', isActive: checkIsActiveTab('#hotels', this.activeTab, true), prefix: 'hotels'})
      }

      if (this.initAttractions) {
        this.tabsInfo.push({name: 'Attractions', isActive: checkIsActiveTab('#attractions', this.activeTab, true), prefix: 'attractions'})
      }

      if (this.initEvents) {
        this.tabsInfo.push({name: 'Events', isActive: checkIsActiveTab('#events', this.activeTab, true), prefix: 'events'})
      }
      if (this.initFlights) {
        this.tabsInfo.push({name: 'Flights', isActive: checkIsActiveTab('#flights', this.activeTab, true), prefix: 'flights'})
      }

      this.tabsKey++

      function checkIsActiveTab(tabPrefix, currentActivePrefix, isInitTab) {
        const _currentActivePrefix = currentActivePrefix || '#hotels'
        return (tabPrefix === _currentActivePrefix) && isInitTab
      }
    }
  }
}

</script>

<style lang="scss" >
.mobile__tabs {
  position: absolute;
  min-height: 100vh;
  width: 100%;

    .main-content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include screen('mobile') {
        height: 100%;
      }

      .slider-thumbnails-wrap {
        height: toRem(258);
        overflow: hidden;
        width: toRem(86);

        .slider-thumbnails {
          .agile__list {
            overflow: visible;
            transform: rotate(90deg) !important;

            .agile__slide {
              border-radius: toRem(6);
              cursor: pointer;
              height: toRem(86);
              max-width: toRem(86);
              overflow: hidden;
              padding: 0 toRem(4);
              transition: transform .2s ease-in-out !important;
              transform: scale(.75) !important;
              width: toRem(86);

              &.agile__slide--active {
                transform: scale(1) !important;
              }
              &:hover {
                transform: scale(1) !important;
              }
              img {
                border-radius: toRem(6);
                height: toRem(86);
                object-fit: cover;
                transform: rotate(-90deg);
                width: toRem(86);
              }
            }
          }
        }
      }
      .center-content {
        flex-grow: 1;
        //padding: toRem(24) toRem(20px);
        @include screen('mobile') {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding-left: 0;
          padding-right: 0;
        }

        .slide-title {
          color: #fff;
          font-size: toRem(44);
          font-weight: 800;
          line-height: 1.3;
          margin: 0 0 toRem(48);
          text-align: center;

          @include screen('mobile') {
            align-items: center;
            display: flex;
            font-size: toRem(36);
            flex-grow: 1;
            justify-content: center;
            margin: 0 0 toRem(24);
            padding: 0;
          }
        }
      }
    }

  .tabs {
    position: relative;
    z-index: 10;

    @include screen('mobile') {
      overflow: hidden;
      max-width: 100vw;
    }
  }

  .tab_body {
    padding: 0;
  }

  .tabs_list-item {
    margin-right: 0;
    width: 50%;
  }

  .search-form-wrap {
    border-bottom: 0;
    @include screen('mobile') {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .tabs-bg.tabs-bg--body {
    display: none;
  }

  .tabs-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tabs-details {
    z-index: 10;
    min-height: calc(100vh - 90px);
    position: absolute;
    width: 98%;
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
